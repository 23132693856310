/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

@font-face {
  font-family: icoMoon;
  src: url(assets/font/icomoon.ttf) format("opentype");
}

html {
    width: 100vw;
    font-size: 12px;
    height: auto;
    overflow: hidden;
    font-family: 'Titillium Web', 'Cairo' !important;
}

body {
    width: 100%;
    height: 100vh;
    margin: 0;
    overflow: auto;
    background-color: #383838;
    -webkit-user-select: none;
    /* Chrome/Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+ */
    /* Rules below not implemented in browsers yet */
    -o-user-select: none;
    user-select: none;
    font-family: 'Titillium Web', 'Cairo' !important;
}

.clickable {
  cursor: pointer;
}

.toast {
  background-color: #ef4643;
  color: white;
  border-radius: 10px;
  font-family: "Titillium Web", "Cairo";
  font-size: 1rem;
  font-weight: 500;
}

.toastSuccess {
  background-color: #00b53e;
  color: white;
  border-radius: 10px;
  font-family: "Titillium Web", "Cairo";
  font-size: 1rem;
  font-weight: 500;
}

.lds-roller {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  }
  .lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 32px 32px;
  }
  .lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #fff;
    margin: -3px 0 0 -3px;
  }
  .lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
  }
  .lds-roller div:nth-child(1):after {
    top: 50px;
    left: 50px;
  }
  .lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
  }
  .lds-roller div:nth-child(2):after {
    top: 54px;
    left: 45px;
  }
  .lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
  }
  .lds-roller div:nth-child(3):after {
    top: 57px;
    left: 39px;
  }
  .lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
  }
  .lds-roller div:nth-child(4):after {
    top: 58px;
    left: 32px;
  }
  .lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
  }
  .lds-roller div:nth-child(5):after {
    top: 57px;
    left: 25px;
  }
  .lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
  }
  .lds-roller div:nth-child(6):after {
    top: 54px;
    left: 19px;
  }
  .lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
  }
  .lds-roller div:nth-child(7):after {
    top: 50px;
    left: 14px;
  }
  .lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
  }
  .lds-roller div:nth-child(8):after {
    top: 45px;
    left: 10px;
  }
  @keyframes lds-roller {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }


  /* Override toggle slide color to match our theme sepcs*/

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #1CB4BC;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #1cb4bc6e;
}
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #1CB4BC;
}

/* Override input color of material form field component */
.mat-form-field-appearance-legacy .mat-form-field-label {
  color: #fafafa !important;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: #fafafa;
}
.mat-focused .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: #fafafa;
}
.mat-calendar-body-selected {
  background-color: #00A0C3;
}
.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #00A0C3;
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: #fafafa;
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #00A0C3;
}
.mat-form-field-appearance-outline .mat-form-field-outline {
  color: #ffffff57;
}
.mat-select-value {
  color: #fff;
}
 .mat-select-arrow {
   color: #fafafa;
 }
.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #00A0C3;
}

/* ************ AGM Styles Override ************ */
.agm-map-container-inner {
  box-shadow: 0 3px 6px #00000029;
  border-radius: 10px 10px 14px 14px ;
}

/********* Scrollbar **************/
 {
  width: 0.5em;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  border-radius: 3rem;
}
::-webkit-scrollbar-button{
  display: none;
}

.spare-form-field-input {
  background: #383838;
  border: 1px solid #5F5F5F;
  border-radius: 5px;
  color: #fff;
  padding: 0 0 0 4px;
}
.spare-form-field-input:focus {
  outline: #1CB4BC auto 1px;
}
